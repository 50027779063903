import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "border-b-1 h-[40px] top-[136px] flex items-center" }
const _hoisted_3 = { class: "border-b border-gray-200" }
const _hoisted_4 = {
  class: "-mb-px flex sm:space-x-8 space-x-0",
  "aria-label": "Tabs"
}
const _hoisted_5 = ["aria-current", "onClick"]

import { reactive, computed, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'


export default {
  __name: 'Tabs',
  props: {
    tabs: {
        type: Array,
        required: true,
        default: () => []
    },
    initialTab: {
        type: String,
        default: null
    }
},
  setup(__props) {

const props = __props

const route = useRoute()
const router = useRouter()

const tabs = reactive(
    props.tabs.map(tab => ({ ...tab, current: false }))
)

const activeTabComponent = computed(() => {
    const activeTab = tabs.find(tab => tab.current)
    return activeTab ? activeTab.content : null
})

watchEffect(() => {
    const tabFromParams = route.query.tab || props.initialTab
    if (tabFromParams) {
        setActiveTabByParam(tabFromParams)
    } else {
        setActiveTab(0)
    }
})

function setActiveTab (index) {
    if (tabs[index].disabled) { return }
    tabs.forEach((tab, i) => {
        tab.current = i === index
    })
    router.push({ query: { tab: tabs[index].param } })
}

function setActiveTabByParam (tabParam) {
    const tab = tabs.find(tab => tab.param === tabParam)
    if (tab && !tab.disabled) {
        tabs.forEach(t => (t.current = false))
        tab.current = true
    }
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs, (tab, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: tab.name,
                class: _normalizeClass(["sm:text-sm text-xs", [
                tab.disabled ? 'text-gray-300 cursor-not-allowed' :
                (tab.current ? 'border-[#1A56DB] text-[#1A56DB]' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'),
                'whitespace-nowrap border-b-[1px] px-1 py-[9px] text-sm cursor-pointer'
              ]]),
                "aria-current": tab.current ? 'page' : undefined,
                onClick: _withModifiers($event => (!tab.disabled && setActiveTab(index)), ["prevent"])
              }, _toDisplayString(tab.name), 11, _hoisted_5))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, `tab-content`, {}, () => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(activeTabComponent.value)))
      ])
    ])
  ]))
}
}

}